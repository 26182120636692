import * as ACTIONS from 'store/actions';

const initialState = {
    getPostTagsLoading: false,
    postTags: [],
    serviceLocationsLoading: false,
    serviceLocations: [],
    professionsLoading: false,
    professions: []
};

const utilReducer = (state = initialState, action) => {
    switch (action.type) {

        case ACTIONS.GET_POST_TAGS_START:
            return {
                ...state,
                getPostTagsLoading: true
            };
        case ACTIONS.GET_POST_TAGS_SUCCESS:
            return {
                ...state,
                getPostTagsLoading: false,
                postTags: action.data
            };
        case ACTIONS.GET_POST_TAGS_FAIL:
            return {
                ...state,
                getPostTagsLoading: false
            };

        case ACTIONS.GET_SERVICE_LOCATIONS_START:
            return {
                ...state,
                serviceLocationsLoading: true
            };
        case ACTIONS.GET_SERVICE_LOCATIONS_SUCCESS:
            return {
                ...state,
                serviceLocationsLoading: false,
                serviceLocations: action.data
            };
        case ACTIONS.GET_SERVICE_LOCATIONS_FAIL:
            return {
                ...state,
                serviceLocationsLoading: false
            };



        case ACTIONS.GET_PROFESSIONS_START:
            return {
                ...state,
                professionsLoading: true
            };
        case ACTIONS.GET_PROFESSIONS_SUCCESS:
            return {
                ...state,
                professionsLoading: false,
                professions: action.data
            };
        case ACTIONS.GET_PROFESSIONS_FAIL:
            return {
                ...state,
                professionsLoading: false
            };

        default:
            return state;
    }
};
export default utilReducer;
