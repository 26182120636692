import { lazy } from 'react';

// project imports
import Loadable from 'components/loadable';
import { Outlet } from 'react-router-dom';
import { ROUTES } from 'utils/common';

const Privacy = Loadable(lazy(() => import('screens/privacy')));
const Offer = Loadable(lazy(() => import('screens/offer')));
const Tos = Loadable(lazy(() => import('screens/tos')));
// login option 3 routing
const Login = Loadable(lazy(() => import('screens/login')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const Landing = {
    path: '/',
    element: <Outlet />,
    children: [
        {
            path: ROUTES.LOGIN,
            element: <Login />
        },
        {
            path: ROUTES.PRIVACY_POLICY,
            element: <Privacy />
        },
        {
            path: ROUTES.OFFERS,
            element: <Offer />
        },
        {
            path: ROUTES.TOS,
            element: <Tos />
        },
    ]
};

export default Landing;
