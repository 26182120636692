import { lazy } from 'react';

// project imports
import Loadable from 'components/loadable';
import MinimalLayout from 'components/minimalLayout';
import { ROUTES } from 'utils/common';

// login option 3 routing
const Home = Loadable(lazy(() => import('screens/home')));
const Profile = Loadable(lazy(() => import('screens/profile')));
const PostDetails = Loadable(lazy(() => import('screens/postDetails')));
const Design = Loadable(lazy(() => import('screens/design')));
const NotLikedPosts = Loadable(lazy(() => import('screens/notLikedPosts')));
const Chat = Loadable(lazy(() => import('screens/chat')));
const Discussion = Loadable(lazy(() => import('screens/discussion')));
const Users = Loadable(lazy(() => import('screens/users')));
const NotFollowedUsers = Loadable(lazy(() => import('screens/notFollowedUsers')));
const ContactUs = Loadable(lazy(() => import('screens/contactus')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const Landing = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: ROUTES.ROOT,
            element: <Home />
        },
        {
            path: ROUTES.USERNAME,
            element: <Profile />
        },
        {
            path: ROUTES.USERNAME2,
            element: <Profile />
        },
        {
            path: ROUTES.DESIGNS,
            element: <Design />
        },
        {
            path: ROUTES.NOT_LIKED_POSTS,
            element: <NotLikedPosts />
        },
        {
            path: ROUTES.NOT_FOLLOWED_USERS,
            element: <NotFollowedUsers />
        },
        {
            path: ROUTES.CHATS,
            element: <Chat />
        },
        {
            path: ROUTES.DISCUSSIONS,
            element: <Discussion />
        },
        {
            path: ROUTES.POST_DETAILS,
            element: <PostDetails />
        },
        {
            path: ROUTES.PROFESSIONALS,
            element: <Users />
        },
        {
            path: ROUTES.CONTACT_US,
            element: <ContactUs />
        }
    ]
};

export default Landing;
