import React, { useState, useEffect } from "react";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { styles } from './styles';
import { Avatar, Divider, MenuItem, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { IconPlus } from "@tabler/icons";
import { CheckCircle } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "utils/common";


export default function SwitchAccountPopup({ setOpen, open }) {

  const [accounts, setAccounts] = useState([]);
  const [currentUser, setCurrentUser] = useState({});

  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const acc = localStorage.getItem('ACCOUNTS');
    if (acc) {
      const accountsArray = JSON.parse(acc);
      if (Array.isArray(accountsArray)) {
        setAccounts(accountsArray);
      }
    }
  }, []);

  useEffect(() => {
    const user = localStorage.getItem('USER_DETAILS');
    if (user) {
      const unstringfyData = JSON.parse(user);
      setCurrentUser(unstringfyData);
    }
  }, []);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <DialogContent
          sx={styles.content}
        >
          <Typography variant="h4">Switch Accounts</Typography>
          <Divider sx={{ width: '400px', mt: '12px' }} />
          <Box sx={styles.box}>
            <MenuItem sx={styles.profileItem} onClick={() => navigate(`/${ROUTES.LOGIN}`)}>
              <Avatar sx={{ bgcolor: '#ffcdd2' }}>
                <IconPlus color='white' />
              </Avatar>
              <Stack sx={{ flex: 1, pl: '16px', pr: '16px' }}>
                <Typography>Login into an Existing Account.</Typography>
              </Stack>
            </MenuItem>

            {accounts.map((item, i) => (
              <MenuItem key={`${i}`} sx={styles.profileItem}
                onClick={() => {
                  localStorage.setItem('USER_DETAILS', JSON.stringify(item));
                  handleClose();
                  window.location.reload(false);
                }}>
                <Avatar sx={{ bgcolor: '#ffcdd2' }} src={item?.image} >
                  <Typography sx={{ color: 'white' }}>
                    {item?.firstName ? item?.firstName[0] : item.userID[0]}
                  </Typography>
                </Avatar>
                <Stack sx={{ flex: 1, pl: '16px', pr: '16px' }}>
                  <Typography>{item?.firstName ? `${item?.firstName || ''} ${item?.lastName || ''}` : item.userID}</Typography>
                  <Typography>{item?.firstName ? item?.userID : '🆕'}</Typography>
                </Stack>
                {currentUser?.userID === item.userID ?
                  <IconButton>
                    <CheckCircle color='primary' />
                  </IconButton>
                  : null}
              </MenuItem>
            ))}
          </Box >
          <IconButton
            edge="start"
            onClick={handleClose}
            aria-label="close"
            sx={styles.closeBtn}
          >
            <CloseIcon sx={styles.closeIcon} />
          </IconButton>
        </DialogContent>
      </Dialog>
    </>
  );
}
