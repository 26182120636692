import React from 'react'
import { styles } from './styles';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';

import {
  IconSettings, IconUserCircle,
  IconApps, IconRowInsertBottom,
  IconLogout, IconUserExclamation,
  IconBorderAll, IconRefresh
} from '@tabler/icons';
import { ROUTES } from 'utils/common';

export default function Profile({ anchorE2, openProfile,
  handleCloseProfile, navigateToPage, currentUser,
  setShowAddPost, setShowAddDiscussion, setSwitchAccountsPopup }) {

  const logout = () => {
    const u = localStorage.getItem('USER_DETAILS');
    if (u) {
      const user = JSON.parse(u);
      const accounts = localStorage.getItem('ACCOUNTS');
      if (accounts) {
        const accountsArray = JSON.parse(accounts);
        if (Array.isArray(accountsArray)) {
          const newAccountArray = accountsArray.filter((item) => item.userID != user.userID)
          localStorage.setItem('ACCOUNTS', JSON.stringify(newAccountArray));
        }
      }
      localStorage.setItem('USER_DETAILS', '');
    }
    navigateToPage(ROUTES.LOGIN)
  }

  const goToProfile = () => {
    navigateToPage(currentUser?.username);
  }

  return (
    <>
      <Menu
        anchorEl={anchorE2}
        id="account-menu"
        open={openProfile}
        onClose={handleCloseProfile}
        onClick={handleCloseProfile}
        PaperProps={styles.box}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >

        <MenuItem onClick={goToProfile}>
          <ListItemIcon>
            <IconUserCircle />
          </ListItemIcon>
          My Profile
        </MenuItem>

        <MenuItem onClick={() => setShowAddPost(true)}>
          <ListItemIcon>
            <IconApps />
          </ListItemIcon>
          Add Post
        </MenuItem>

        <MenuItem onClick={() => setShowAddDiscussion(true)}>
          <ListItemIcon>
            <IconRowInsertBottom />
          </ListItemIcon>
          Add Discussion
        </MenuItem>

        <MenuItem onClick={() => navigateToPage('some/where/in/www/nlp')}>
          <ListItemIcon>
            <IconBorderAll />
          </ListItemIcon>
          Not Liked Posts
        </MenuItem>

        <MenuItem onClick={() => navigateToPage('some/where/in/www/nfu')}>
          <ListItemIcon>
            <IconUserExclamation />
          </ListItemIcon>
          Not Followed Users
        </MenuItem>
        <Divider />

        <MenuItem>
          <ListItemIcon>
            <IconSettings />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem onClick={() => setSwitchAccountsPopup(true)}>
          <ListItemIcon>
            <IconRefresh />
          </ListItemIcon>
          Switch Accounts
        </MenuItem>
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <IconLogout />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  )
}
