export const styles = {
  dialogbox: {
    backgroundColor: "transparent",
    boxShadow: "none",
  },
  content: {
    display: "flex",
    flexDirection: 'column',
    alignItems: "center",
    backgroundColor: 'white',
    borderRadius: '10px',
    width: '340px',
    pt: '12px',
  },
  box: {
    display: "flex",
    flexDirection: 'column',
    alignItems: "center",
    maxHeight: '360px',
    width: '340px',
    overflow: 'scroll',
  },
  closeBtn: {
    position: "absolute",
    top: '4px',
    right: '4px',
  },
  closeIcon: {
    color: "black",
  },
  profileItem: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    padding: '10px',
  }
};
