import { Outlet } from 'react-router-dom';
import Navbar from "../../components/navbar";

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => (
    <>
        <Navbar />
        <Outlet />
    </>
);

export default MinimalLayout;
