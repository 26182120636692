import { put, takeEvery, call } from 'redux-saga/effects';
import * as ACTION from 'store/actions';

import Fetch from '../../network';
import * as Constant from '../constant';

function* addInquiry(action) {
    try {
        const params = action.params;
        const response = yield call(new Fetch().fetchRequest, 'POST', Constant.ADD_INQUIRY, params);

        if (response.status === 200) {
            action.callBack('success', 'We will contact you soon!');
        } else {
            action.callBack('error', 'Something went wrong.');
        }
    } catch (err) {
        action.callBack('error', 'Something went wrong.');
    }
}

function* getPostTags(action) {
    try {
        const params = action.params;
        const response = yield call(new Fetch().fetchRequest, 'GET', Constant.GET_POST_TAGS, params);
        if (response.status === 200) {
            yield put({ type: ACTION.GET_POST_TAGS_SUCCESS, data: response.data });
        } else {
            yield put({ type: ACTION.GET_POST_TAGS_FAIL });
        }
    } catch (err) {
        yield put({ type: ACTION.GET_POST_TAGS_FAIL });
    }
}

function* getServiceLocations(action) {
    try {
        const params = action.params;
        const response = yield call(new Fetch().fetchRequest, 'GET', Constant.GET_SERVICE_LOCATIONS, params);
        if (response.status === 200) {
            yield put({ type: ACTION.GET_SERVICE_LOCATIONS_SUCCESS, data: response.data });
        } else {
            yield put({ type: ACTION.GET_SERVICE_LOCATIONS_FAIL });
        }
    } catch (err) {
        yield put({ type: ACTION.GET_SERVICE_LOCATIONS_FAIL });
    }
}


function* getProfessions(action) {
    try {
        const params = action.params;
        const response = yield call(new Fetch().fetchRequest, 'GET', Constant.GET_PROFESSIONS, params);
        if (response.status === 200) {
            yield put({ type: ACTION.GET_PROFESSIONS_SUCCESS, data: response.data });
        } else {
            yield put({ type: ACTION.GET_PROFESSIONS_FAIL });
        }
    } catch (err) {
        yield put({ type: ACTION.GET_PROFESSIONS_FAIL });
    }
}


export function* watchGetPostTags() {
    yield takeEvery(ACTION.GET_POST_TAGS_START, getPostTags);
}


export function* watchGetServiceLocations() {
    yield takeEvery(ACTION.GET_SERVICE_LOCATIONS_START, getServiceLocations);
}

export function* watchGetProfessions() {
    yield takeEvery(ACTION.GET_PROFESSIONS_START, getProfessions);
}

export function* watchAddInquiry() {
    yield takeEvery(ACTION.ADD_INQUIRY_START, addInquiry);
}
