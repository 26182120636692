export const styles = {
  logo: mobile => ({
    height: mobile ? '16px' : '24px',
    width: mobile ? '60px' : '80px',
    cursor: 'pointer'
  }),
  buttons: (selected, mobile) => ({
    color: selected ? 'primary' : '#000',
    fontSize: mobile ? '12px' : '14px'
  }),
  logoBox: mobile => ({ width: mobile ? '8%' : '12.5%' }),
  rightBox: mobile => ({ marginLeft: mobile ? '10%' : 0, width: '74%', display: 'flex', justifyContent: 'space-between' }),
  logoText: mobile => ({
    fontSize: '20px',
    color: '#444444',
    fontWeight: '700'
  }),
  avatar: (border, mobile) => ({
    width: mobile ? '16px' : '24px',
    height: mobile ? '16px' : '24px',
    border: `${border ? '2px' : '0px'} solid #00abfb`
  }),
  stack: {
    alignItems: 'center',
    '@media (max-width: 700px)': {
      ml: "15%"
    },
  },
};
